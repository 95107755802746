export default {
	state  : () => ({
		PACKAGE_VERSION: process.env.PACKAGE_VERSION,
	}),
	getters: {
		PACKAGE_VERSION(state) {
			return state.PACKAGE_VERSION;
		},
	},
};
