import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions'
import mutations from './mutations'

import metaModule from '@/store/modules/metaModule'
import { store as pi } from 'piramis-base-components/src/components/Pi'
import getters from '@/store/getters'
import state from '@/store/state'

Vue.use(Vuex)

export default new Vuex.Store({
        state,
        actions,
        mutations,
        getters,
        modules:{
            metaModule,
            pi
        }
    }
)
