import BaseApi from '@/includes/services/BaseApi'
import { User } from '@/includes/types/User'

type CreateUserBodyReq = {
    phone: string
}

export type CreateUserStage = 'Code' | 'Password' | 'Complete'

type BaseCreateUserFields = {
    key: string
    stage: CreateUserStage
}

export type CreateUserRes = BaseCreateUserFields

type ConfirmUserRes = { user: User } & BaseCreateUserFields

export type UserConfig = {
    events: Record<string, number>
}

export type ReqUserConfig = {
    user_key: string
    config: UserConfig
    triggers: Array<number>
}

export type UserConfigLimits = {
    options: Array<string>,
    group_send_interval: number,
    send_interval: number
}

export type ResUserConfig =
    Omit<ReqUserConfig, 'user_key'>
    & {
    user: User,
    limits: UserConfigLimits
}

export type Statistic = {
    userId: number
    time: string
    count: number
}

export type UserStatistics = {
    users: Array<User>
    statistics: Array<Statistic>
}

export class UserService {

    static async createUser(type: string, body: CreateUserBodyReq): Promise<CreateUserRes> {
        return await BaseApi.sendRequest(type, 'createuser', body)
    }

    static async deleteUser(type: string, body: { user_key: string }): Promise<void> {
        return await BaseApi.sendRequest(type, 'deleteuser', body)
    }

    static async confirmUser(type: string, body): Promise<ConfirmUserRes> {
        return await BaseApi.sendRequest(type, 'confirmuser', body)
    }

    static async getUsers(type: string, body): Promise<Array<User>> {
        const { users } = await BaseApi.sendRequest(type, 'getusers', body)
        return users
    }

    static async getUsersStat(type: string, body): Promise<UserStatistics> {
        const { users, statistics } = await BaseApi.sendRequest(type, 'getusersstat', body)
        return { users, statistics }
    }

    static async getUserConfig(type: string, body: { user_key: string }): Promise<ResUserConfig> {
        return await BaseApi.sendRequest(type, 'getuserconfig', body)
    }

    static async setUserConfig(type: string, body: ReqUserConfig): Promise<ResUserConfig> {
        return await BaseApi.sendRequest(type, 'setuserconfig', body)
    }

    static async getUsersStatus(type: string, body): Promise<{ users: Array<{ user_id: number, status: string }> }> {
        return await BaseApi.sendRequest(type, 'getusersstatus', body)
    }
}