import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import { ReqUserConfig, UserService } from '@/includes/services/UserService'
import { User } from '@/includes/types/User'
import store from '@/store/store'
import i18n from '@/i18n/i18n'

import Vue from 'vue'

export default {
    REQUEST_USERS({ commit }): Promise<Array<User>> {
        return new Promise(resolve => {
            UserService.getUsers('tg', null)
                .then(users => {
                    commit('SET_USERS_TO_STORE', users)
                    resolve(users)
                })
                .catch(errorNotification)
        })
    },
    REQUEST_USER_CONFIG({ commit }, user_key: string): Promise<void> {
        return new Promise(resolve => {
            UserService.getUserConfig('tg', { user_key })
                .then(config => {
                    commit('SET_USER_TO_STORE', { user: config.user, config: config.config, limits: config.limits })
                    commit('SET_ACTIVE_TRIGGERS_IDS', config.triggers)
                    resolve()
                })
                .catch(errorNotification)
        })
    },
    SET_USER_CONFIG({ commit }, userKey: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const userConfig: ReqUserConfig = {
                user_key: userKey,
                triggers: store.state.triggerState.activeTriggers,
                config: store.state.userState.currentUserConfig!.config,
            }

            UserService.setUserConfig('tg', userConfig)
                .then(config => {
                    commit('SET_ACTIVE_TRIGGERS_IDS', config.triggers)
                    commit('SET_USER_TO_STORE', config)
                    resolve()
                })
                .catch((error: any) => reject(error))
        })
    },
    SAVE_CONFIG({ commit }, user_key:string) {
        Vue.prototype.$baseTemplate.loader.open()
        store.dispatch('SET_USER_CONFIG', user_key)
            .then(() => {
                successNotification()
            })
            .catch(errorNotification)
            .finally(() => {
                Vue.prototype.$baseTemplate.loader.close()
            })
    },
    SAVE_CONFIG_WARN({ commit, dispatch }, user_key: string) {
        return new Promise((resolve, reject) => {
            Vue.prototype.$confirm({
                title: i18n.t('triggers_save_settings_title').toString(),
                content: i18n.t('triggers_save_settings_text').toString(),
                okText: i18n.t('triggers_save_settings_accept').toString(),
                okType: 'primary',
                cancelText: i18n.t('triggers_save_settings_reject').toString(),
                centered: true,
                onOk: () => {
                    dispatch('SAVE_CONFIG')
                },
                // onCancel: () => {
                //     // reject(false)
                // }
            })
        })
    }
}