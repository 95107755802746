import { User } from '@/includes/types/User'
import { UserConfig, UserConfigLimits } from '@/includes/services/UserService'

export type UserState = {
    allUsers: Array<User> | null
    currentUserConfig: { user: User, config: UserConfig, limits: UserConfigLimits } | null
}

export const userState: UserState = {
    allUsers: null,
    currentUserConfig: null
}
