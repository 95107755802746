import BaseApi from '@/includes/services/BaseApi'
import { Trigger, TriggerBaseReq, SetTrigger, TriggerStatRecord } from '@/includes/types/Triggers'
import { RawTriggerData } from '@/components/TriggerSetup/logic/types/types'

export default class TriggerService {

    static async getTriggers(type: string, body: { user_key: string }): Promise<Array<Trigger>> {
        return await BaseApi.sendRequest(type, 'gettriggers', body)
    }

    static async createTrigger(type: string, body: { user_key: string, trigger: RawTriggerData }): Promise<Trigger> {
        return await BaseApi.sendRequest(type, 'createtrigger', body)
    }

    static async deleteTrigger(type: string, body: TriggerBaseReq): Promise<{ key: string, active: boolean }> {
        return await BaseApi.sendRequest(type, 'deletetrigger', body)
    }

    static async getTrigger(type: string, body: TriggerBaseReq): Promise<Trigger> {
        return await BaseApi.sendRequest(type, 'gettrigger', body)
    }

    static async setTrigger(type: string, body: SetTrigger): Promise<Trigger> {
        return await BaseApi.sendRequest(type, 'settrigger', body)
    }

    static async getTriggersStat(type: string, body: { user_key: string, from: string, to: string }): Promise<{items: Array<{stat: Array<TriggerStatRecord>} & Trigger>}> {
        return await BaseApi.sendRequest(type, 'gettriggersstat', body)
    }

}