import { RootState } from '@/store/state'
import { TriggerData } from '@/components/TriggerSetup/logic/types/types'
import { Trigger } from '@/includes/types/Triggers'

export default {
    SET_ALL_TRIGGERS_TO_STORE(state: RootState, triggers: Array<Trigger>) {
        state.triggerState.allTriggers = triggers
    },
    SET_FRONTEND_TRIGGERS(state: RootState, triggers: Array<Trigger>) {
        state.triggerState.frontendTriggers = triggers
    },
    REMOVE_FRONTEND_TRIGGER(state: RootState, trigger_key: string) {
        const index = state.triggerState.frontendTriggers?.findIndex(value => value.key === trigger_key)
        if (index != null) {
            state.triggerState.frontendTriggers!.splice(index, 1)
        }
    },
    CLEAR_TRIGGERS_FROM_STORE(state: RootState) {
        state.triggerState.allTriggers = null
    },
    SET_ACTIVE_TRIGGERS_IDS(state: RootState, triggers: Array<number>) {
        state.triggerState.activeTriggers = triggers.slice()
    },
    CLEAR_ACTIVE_TRIGGERS_IDS(state: RootState) {
        state.triggerState.activeTriggers = []
    },
    ADD_ACTIVE_TRIGGER_ID(state: RootState, triggerKey: string) {
        const triggerId = Number.parseInt(triggerKey.split('.')[0])
        state.triggerState.activeTriggers!.push(triggerId)
    },
    UPDATE_ACTIVE_TRIGGERS(state: RootState, triggers: Array<number>) {
        state.triggerState.activeTriggers = triggers
    },
    REMOVE_TRIGGER_FROM_ACTIVE(state: RootState, triggerKey: string) {
        const index = state.triggerState.allTriggers!.findIndex(value => value.key === triggerKey)
        state.triggerState.activeTriggers.splice(index, 1)
    },
    SET_TRIGGER_COPY(state: RootState, triggerData: TriggerData | null) {
        state.triggerState.triggerCopy = triggerData
    }
}