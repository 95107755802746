import { TriggerData } from '@/components/TriggerSetup/logic/types/types'
import { Trigger } from '@/includes/types/Triggers'

export type TriggerState = {
    frontendTriggers: Array<Trigger> | null,
    allTriggers: Array<Trigger> | null
    activeTriggers: Array<number>
    triggerCopy: TriggerData | null
}

export const triggerState: TriggerState = {
    frontendTriggers: null,
    allTriggers: null,
    activeTriggers: [],
    triggerCopy: null
}

