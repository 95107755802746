import TriggerService from '@/includes/services/TriggerService'
import { TriggerFactory } from '@/components/TriggerSetup/logic/factories/trigger-factory'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import { TriggerBaseReq } from '@/includes/types/Triggers'

export default {
    REQUEST_TRIGGERS({ commit }, { user_key }: { user_key: string }) {
        return new Promise(resolve => {
            TriggerService.getTriggers('tg', { user_key })
                .then((triggers: Array<any>) => {
                    commit('SET_ALL_TRIGGERS_TO_STORE', (triggers as any).items)
                    commit('SET_FRONTEND_TRIGGERS', (triggers as any).items)
                    resolve(triggers)
                })
                .catch(errorNotification)
        })
    },
    DELETE_FRONTEND_TRIGGER({ commit }, { trigger_key, user_key }: TriggerBaseReq) {
        TriggerService.deleteTrigger('tg', {
            user_key,
            trigger_key
        })
            .then((response) => {
                commit('REMOVE_FRONTEND_TRIGGER', response.key)
                successNotification()
            })
            .catch(errorNotification)
    },
    MAKE_TRIGGER_COPY({ commit, state }, key: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const trigger = state.triggerState.frontendTriggers?.find(t => t.key === key)
            if (trigger) {
                const triggerFactory = new TriggerFactory()
                commit('SET_TRIGGER_COPY', triggerFactory.createNewTriggerFromExists(trigger.config))
                resolve()
            } else {
                errorNotification('Trigger not found')
                reject()
            }
        })
    }
}