import i18n from "@/i18n/i18n";
import store from "@/store/store";

import TemplateContext from 'piramis-base-components/src/plugins/AdminTemplate/includes/TemplateContext'

export function actionTypeBreadcrumb(ctx: TemplateContext):string {
  let label = ''

  if (ctx?.route.params.actionType === 'new') label = i18n.t('page_action_type_new').toString()
  if (ctx?.route.params.actionType === 'edit') label = i18n.t('page_action_type_edit').toString()
  if (ctx?.route.params.actionType === 'watch') label = i18n.t('page_action_type_watch').toString()

  return label
}

export const baseUserBreadcrumbs = () => {
  const userConfig = store.state.userState.currentUserConfig

  if (userConfig) {
    return [
      {
        iconName: 'icon-message-circle',
        routeName: 'Users',
      },
      {
        routeName: 'User',
        image: () => userConfig.user.avatar,
        label: () => userConfig.user.username + " (" + userConfig.user.title + ")"
      },
    ]
  }

  return []

}