import axios from "axios";
import { isEmpty }     from "lodash";

export default {
	async getStatisticsLocales({ state, commit }) {
		if(isEmpty(state.statistics.locales)) {
			try {
				let en = await axios
					.get("https://cdn.jsdelivr.net/npm/apexcharts/dist/locales/en.json");

				let ru = await axios
					.get("https://cdn.jsdelivr.net/npm/apexcharts/dist/locales/ru.json");

				const locales = [ en.data, ru.data ];

				commit("updateStatisticsLocales", locales);

				return true;
			} catch(err) {
				console.error(err);
				return false;
			}
		} else {
			return true;
		}
	},
};
