import {
    IterableListItem,
    RawTriggerData,
    TriggerCondition,
    TriggerData
} from '@/components/TriggerSetup/logic/types/types'

import { cloneDeep } from 'lodash'
import { IterableItemFactory } from '@/components/TriggerSetup/logic/factories/iterable-item-factory'

export class TriggerFactory {

    iterableItemFactory: IterableItemFactory = new IterableItemFactory()

    getTriggerTemplate(): RawTriggerData {
        return {
            name: '',
            workspace: 'Group',
            user_key: null,
            actionExecuteType: 'All',
            chance: 100,
            processAlbumsLikeFirstPhoto: true,
            conditions: [ [] ],
            actions: [ this.actionGroupTemplate ],
        }
    }

    get actionGroupTemplate(): any {
        return {
            from: 0,
            to: 0,
            chance: 100,
            actions: [],
        }
    }

    createConditionsGroup(value?: Array<IterableListItem<TriggerCondition>>): IterableListItem<Array<IterableListItem<TriggerCondition>>> {
        return this.iterableItemFactory.create<Array<IterableListItem<TriggerCondition>>>(value || [])
    }

    createCondition(value: TriggerCondition): IterableListItem<TriggerCondition> {
        return this.iterableItemFactory.create(cloneDeep(value))
    }

    createActionGroup(value?: any): any {
        return this.iterableItemFactory.create<any>({ ...this.actionGroupTemplate, ...value } || this.actionGroupTemplate)
    }

    createAction(value: any): IterableListItem<any> {
        return this.iterableItemFactory.create(cloneDeep(value))
    }

    processRawTriggerDataForFrontend(rawTriggerData: RawTriggerData): TriggerData {
        const rawTriggerDataCopy: RawTriggerData = cloneDeep(rawTriggerData)

        const conditions: Array<IterableListItem<Array<IterableListItem<TriggerCondition>>>> =
            rawTriggerDataCopy.conditions
                .map((conditionGroup: Array<TriggerCondition>) =>
                    (this.createConditionsGroup(conditionGroup.map((condition: TriggerCondition) => this.createCondition(condition)))))

        const actions: { [key: string]: any } = {}
        const actionsKeys: Array<string> = [ 'actions' ]
        actionsKeys.forEach((key: string) => {
            if ((rawTriggerDataCopy as { [key: string]: any })[key]) {
                actions[key] = ((rawTriggerDataCopy as { [key: string]: any })[key] as Array<any>)
                    .map((actionGroup: any) => {
                        actionGroup.actions = actionGroup.actions.map((action: any) => this.createAction(action))
                        return this.createActionGroup(actionGroup)
                    })
            } else {
                actions[key] = []
            }
        })

        return { ...rawTriggerDataCopy, conditions, ...actions } as any as TriggerData
    }

    triggerDataToRawTriggerData(triggerData: TriggerData): RawTriggerData {
        const triggerDataCopy: TriggerData = cloneDeep(triggerData)
        const getValues = (item: Array<IterableListItem<any>>, type?: string): any => {

            if (type === 'conditions') {
                return this.processConditions(item)
            } else {
                const listWithValuesOnly = item.map((child) => {
                    if (child.value.actions) {
                        return child.value
                    }
                })

                if (listWithValuesOnly.length) {
                    return listWithValuesOnly.map((subChild: any) => {
                        if (subChild && subChild.actions) {
                            subChild.actions = subChild.actions.map((action: any) => {
                                return action.value
                            })
                            return subChild
                        }
                    })
                }
            }
        }

        return {
            ...triggerDataCopy,
            actions: getValues(triggerDataCopy.actions, 'actions'),
            conditions: getValues(triggerDataCopy.conditions, 'conditions'),
        } as unknown as RawTriggerData
    }

    processConditions(item: Array<IterableListItem<any>>) {
        return item.map((child) => {
            if (child.value.actions) {
                return (child.value.actions as Array<IterableListItem<any>>).map((subChild) => {
                    return subChild.value
                })
            }

            if (Array.isArray(child.value)) {
                return (child.value as Array<IterableListItem<any>>).map((subChild) => {
                    return subChild.value
                })
            }
        })
    }

    createNewTrigger(): TriggerData {
        const triggerData: RawTriggerData = cloneDeep(this.getTriggerTemplate())
        return this.processRawTriggerDataForFrontend(triggerData)
    }

    createNewTriggerFromExists(triggerData: RawTriggerData): TriggerData {
        triggerData = cloneDeep(triggerData)
        return this.processRawTriggerDataForFrontend({ ...this.getTriggerTemplate(), ...triggerData })
    }
}
