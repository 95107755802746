export type StatisticsState = {
    statistics: {
        locales: Array<string>
    }
}

export default {
    statistics: {
        locales: [],
    },
}

