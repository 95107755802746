import { RootState } from '@/store/state'
import { User } from '@/includes/types/User'

export default {
    getCurrentUser: (state: RootState) => (userKey: string): User | null => {
        if (state.userState.allUsers) {
            const user = state.userState.allUsers.find(user => user.key.split('.')[0] === userKey)
            return user ? user : null
        }
        return null
    },
    eventNameByKey: (state: RootState) => (eventKey:number): string | null => {
        const config = state.userState.currentUserConfig

        if (config && Object.keys(config.config.events).length) {
            for (let [ key, value ] of Object.entries(config.config.events)) {
                if (value === eventKey) {
                    return key
                }
            }
        }

        return null
    }
}
