import Vue from "vue";
// v-select component
import vSelect from "vue-select";
import 'vue-select/src/scss/vue-select.scss'
Vue.component(vSelect);

// feather
import FeatherIcon from "./components/FeatherIcon.vue";
Vue.component(FeatherIcon.name, FeatherIcon);

