import {
  errorNotificationWrapper,
  successNotificationWrapper,
  warningNotificationWrapper
} from 'piramis-base-components/src/logic/helpers/NotificationService'

import i18n from '@/i18n/i18n'

export function successNotification(message = i18n.t('success').toString(), description = ''): void {
  successNotificationWrapper(message, description)
}

export function errorNotification(error: any): void {
  errorNotificationWrapper(i18n.t('error').toString(), error)
}

export function warningNotification(description: string): void {
  warningNotificationWrapper(i18n.t('warning').toString(), description)
}