import '@/assets/css/main.css'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'material-icons/iconfont/material-icons.css'
import axios from 'axios'
import { localize } from 'vee-validate'
// @ts-ignore
import en from 'vee-validate/dist/locale/en'
// @ts-ignore
import ru from 'vee-validate/dist/locale/ru'
import 'video.js/dist/video-js.min.css'

import Vue, { PluginFunction, VNode } from 'vue'

// @ts-ignore
import checkView from 'vue-check-view'
import { DynamicReactiveRefs, ReactiveRefs } from 'vue-reactive-refs'
import VueScreen from 'vue-screen'
// @ts-ignore
import { VueHammer } from 'vue2-hammer'
import vuescroll from 'vuescroll'
import App from './App.vue'
import './globalComponents.js'
import i18n from './i18n/i18n'
import router from './router/index'
import store from './store/store'
import isMobile from 'piramis-js-utils/lib/isMobile'
// @ts-ignore
import { Plugin } from 'vue-fragment'

// @ts-ignore
import VueAwesomeSwiper from 'vue-awesome-swiper'
// require styles
import 'swiper/dist/css/swiper.css'

import RouteHelpers from 'piramis-base-components/src/plugins/RouteHelpers/index'
import AdminTemplate from 'piramis-base-components/src/plugins/AdminTemplate'
import { MenuItemType } from 'piramis-base-components/src/plugins/AdminTemplate/includes/types/MenuItem'
import { AdminTemplateOptions, Layout } from 'piramis-base-components/src/plugins/AdminTemplate/includes/types/Config'
import { User } from '@/includes/types/User'
import { ProductNames } from 'piramis-base-components/src/plugins/AdminTemplate/components/OurProducts/types'

import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import 'piramis-base-components/src/assets/scss/main.scss'
import { actionTypeBreadcrumb, baseUserBreadcrumbs } from '@/includes/AppConfigBreadcrumbsHelper'
import { Guid } from 'guid-typescript'
import { errorNotification } from '@/includes/services/NotificationService'

Vue.use(Antd)

// Feather font icon
require('./assets/css/iconfont.css')

localize({
    ru,
    en,
})

localize(i18n.locale)

Vue.use(RouteHelpers as unknown as PluginFunction<any>, { router })

Vue.use(VueAwesomeSwiper)
Vue.use(Plugin)
Vue.use(checkView)
Vue.use(VueScreen, 'tailwind')
Vue.use(vuescroll)
Vue.prototype.$http = axios
Vue.use(VueHammer)
Vue.use(DynamicReactiveRefs)
Vue.use(ReactiveRefs)

const adminTemplateOptions: AdminTemplateOptions = {
    config: {
        meta: {
            projectName: 'TgScout.app',
            apiUrl: 'api.tgscout.app/cabinet',
            projectDomain: 'https://tgscout.app',
            sidebarLogo: require('./assets/images/tgscout-logo/logo_title.png'),
            loginPageLogo: require('./assets/images/tgscout-logo/login_page_logo.png'),
            tgResolvers: {
                supportBotDomain: 'TgScoutSupportRu',
                promotionBotDomain: 'ChatKeeperPromotionBot',
                mainBotDomain: 'tgscoutbot',
            },
            login: {
                botAuthLink: 'https://t.me/tgscoutbot?start=cabinetlink',
                loginWidget: {
                    botLogin: 'tgscoutbot',
                    tgWidgetAuthLink: 's/tg/d/login?bot_id=1047364105',
                }
            },
            languageCodes: [ 'ru', 'en' ],
            isSaveButtonVisible: false,
            paymentEntity: {
                target_type: (ctx) => {
                    return {
                        target_id: {
                            user_key: store.state.userState.allUsers!.find(u => u.key.split('.')[0] === ctx?.route.params.id)!.key,
                        },
                        target_type: 'user'
                    }
                }
            },
        },
        footer: {
            version: store.getters.PACKAGE_VERSION,
            ourProducts: {
                exclude: [ ProductNames.TGSCOUTBOT ]
            }
        },
        loyaltySystem: {
            partnerBlock: {
                partnerLink: (localeCode) => 'https://t.me/ChatKeeperPromotion/?start=loyalty_parnters'
            },
            companiesBlock: {
                termsOwnerLink: (localeCode) => 'https://t.me/ChatKeeperPromotion/?start=loyalty_parnters'
            }
        },
        companyRegistration: {
            powerOfAttorney: (localeCode): string => 'https://tgscout.app/wp-content/uploads/2022/06/power_of_attorney_ur.doc',
            howToRegisterUr: (localeCode): string => 'https://tgscout.app/wp-content/uploads/2022/06/how_to_register_ur-3.docx',
        },
        search: {
            entities: (ctx): any => {
                if (store.state.userState.allUsers !== null && Array.isArray(store.state.userState.allUsers) && store.state.userState.allUsers.length) {
                    return store.state.userState.allUsers.map((e: any) => {
                        e.id = e.key.split('.')[0]
                        return e
                    })
                } else {
                    return []
                }
            },
            path: (ctx, router, selectedId): void => {
                if (selectedId) {
                    router.push({
                        name: 'User',
                        params: {
                            id: selectedId.toString(),
                        }
                    })
                }
            }
        },
        rightScreenButton: {
            link: (localeCode): string => {
                if (localeCode === 'ru') return 'https://t.me/PressCodeSupportRu'
                else return 'https://t.me/PressCodeSupport'
            },
        },
        serviceData: {
            type: 'ServiceData',
            tariffs: {
                starter: {
                    type: 'TariffInfo',
                    img: require('./assets/images/payments/tariffs/starter.png'),
                    color: 'rgb(181,192,212)'
                },
                advanced: {
                    type: 'TariffInfo',
                    img: require('./assets/images/payments/tariffs/advanced.png'),
                    color: 'rgb(241,154,64)'
                },
                ultimate: {
                    type: 'TariffInfo',
                    img: require('./assets/images/payments/tariffs/ultimate.png'),
                    color: 'rgb(177,69,250)',
                },

            },
        },
        topMenu: [
            {
                menuItem: {
                    guid: '123',
                    type: MenuItemType.DropDown,
                    name: 'help',
                    featherIcon: true,
                    icon: 'help-circle',
                    isVisible: [ true ],
                    class: [ 'text-warning' ],
                    submenu: [
                        {
                            menuItem: {
                                isVisible: [ true ],
                                type: MenuItemType.DropDownLinkComponent,
                                name: 'regexp',
                                icon: 'flag',
                                featherIcon: true,
                                component: (): any => import('piramis-base-components/src/plugins/AdminTemplate/components/regexp-validator/RegexpValidator.vue'),
                            },
                        },
                    ],
                },
            },
        ],
        sidebarMenu: [
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-users',
                    isVisible: [ true ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Users',
                            path: '/',
                            component: (): any => import('./views/users.vue'),
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.DynamicHeader,
                    isVisible: [
                        (ctx): boolean => !!ctx.route.params.id,
                    ],
                    header: (ctx): string => {
                        const user = store.getters.getCurrentUser(ctx.route.params.id)

                        return user.username ?? user.title
                    },
                    avatar: (ctx): string => store.getters.getCurrentUser(ctx.route.params.id).avatar,
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-user',
                    isVisible: [ (ctx): boolean => !!ctx.route.params.id ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'User',
                            path: '/user/:id',
                            meta: {
                              breadcrumbs: (ctx) => {
                                  return [
                                      ...baseUserBreadcrumbs(),
                                      { label: i18n.t('menu_user_title') }
                                  ]
                              }
                            },
                            component: (): any => import('./views/user-dashboard.vue'),
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-bell',
                    isVisible: [ (ctx): boolean => !!ctx.route.params.id ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Events',
                            path: '/user/:id/events',
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseUserBreadcrumbs(),
                                        { label: i18n.t('menu_events_title') }
                                    ]
                                }
                            },
                            component: (): any => import('./views/events.vue'),
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    icon: 'icon-zap',
                    isVisible: [ (ctx): boolean => !!ctx.route.params.id ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Triggers',
                            path: '/user/:id/triggers',
                            meta: {
                                breadcrumbs: (ctx) => {
                                    return [
                                        ...baseUserBreadcrumbs(),
                                        { label: i18n.t('menu_triggers_title') }
                                    ]
                                }
                            },
                            component: (): any => import('./views/triggers.vue'),
                        },
                    },
                    class: [],
                },
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    featherIcon: true,
                    isVisible: [ false ],
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Trigger',
                            path: '/user/:id/trigger/:actionType',
                            component: (): any => import('./views/trigger.vue'),
                            meta: {
                                breadcrumbs: (ctx) => {
                                    const query = ctx.route.query.triggerKey;
                                    const trigger = store.state.triggerState.frontendTriggers?.find(t => t.key === query)

                                        return [
                                            ...baseUserBreadcrumbs(),
                                            { label: i18n.t('menu_triggers_title'), routeName: 'Triggers' },
                                            ...trigger ? [ { label: trigger.config.name } ] : [],
                                            { label: actionTypeBreadcrumb(ctx) }
                                        ]
                                }
                            },
                        },
                    },
                    class: [],
                },
            },
            // {
            //     menuItem: {
            //         type: MenuItemType.Route,
            //         featherIcon: true,
            //         icon: 'icon-bar-chart',
            //         isVisible: [ (ctx): boolean => !!ctx.route.params.id ],
            //         routeConfig: {
            //             layout: Layout.Panel,
            //             config: {
            //                 name: 'Triggers_statistics',
            //                 path: '/user/:id/statistics',
            //                 meta: {
            //                     breadcrumbs: (ctx) => {
            //                         return [
            //                             ...baseUserBreadcrumbs(),
            //                             { label: i18n.t('menu_triggers_statistics_title') }
            //                         ]
            //                     }
            //                 },
            //                 component: (): any => import('./views/statistics/triggers-statistics.vue'),
            //             },
            //         },
            //         class: [],
            //     },
            // },
            {
                menuItem: {
                    type: MenuItemType.DropDown,
                    isVisible: [ (ctx): boolean => !!ctx.route.params.id ],
                    featherIcon: true,
                    icon: 'icon-bar-chart',
                    name: 'Statistics_tab',
                    guid: Guid.create().toString(),
                    submenu: [
                        {
                            menuItem: {
                                type: MenuItemType.Route,
                                featherIcon: true,
                                icon: 'icon-zap',
                                isVisible: [ (ctx): boolean => !!ctx.route.params.id ],
                                routeConfig: {
                                    layout: Layout.Panel,
                                    config: {
                                        name: 'Triggers_statistics',
                                        path: '/user/:id/statistics',
                                        meta: {
                                            breadcrumbs: (ctx) => {
                                                return [
                                                    ...baseUserBreadcrumbs(),
                                                    { label: i18n.t('menu_statistics_tab_title') },
                                                    { label: i18n.t('menu_triggers_statistics_title') }
                                                ]
                                            }
                                        },
                                        component: (): any => import('./views/statistics/triggers-statistics.vue'),
                                    },
                                },
                                class: [],
                            },
                        },
                        {
                            menuItem: {
                                type: MenuItemType.Route,
                                featherIcon: true,
                                icon: 'icon-bell',
                                isVisible: [ (ctx): boolean => !!ctx.route.params.id ],
                                routeConfig: {
                                    layout: Layout.Panel,
                                    config: {
                                        name: 'Events_statistics',
                                        path: '/user/:id/events-statistics',
                                        meta: {
                                            breadcrumbs: (ctx) => {
                                                return [
                                                    ...baseUserBreadcrumbs(),
                                                    { label: i18n.t('menu_statistics_tab_title') },
                                                    { label: i18n.t('menu_events_statistics_title') }
                                                ]
                                            }
                                        },
                                        component: (): any => import('./views/statistics/events-statistics.vue'),
                                    },
                                },
                                class: [],
                            },
                        },
                    ]
                }
            },
            {
                menuItem: {
                    type: MenuItemType.Route,
                    isVisible: [ false ],
                    featherIcon: true,
                    routeConfig: {
                        layout: Layout.Panel,
                        config: {
                            name: 'Buy',
                            path: '/user/:id/buy',
                            component: (): any => import('@/views/license-buy.vue'),
                        },
                    },
                    class: [ 'vu-nav-license' ],
                },
            },
        ],
        globalGuards: {
            beforeEach: async (to, next): Promise<void> => {
                if (!to.params.id) {
                    await store.commit('CLEAR_TRIGGERS_FROM_STORE')
                    await store.commit('CLEAR_ACTIVE_TRIGGERS_IDS')
                    await store.commit('CLEAR_USER_FROM_STORE')
                }

                if (to.params.id) {
                    if (!store.state.userState.allUsers) {
                        await store.dispatch('REQUEST_USERS')
                    }

                    const user: User | null = store.getters.getCurrentUser(to.params.id)

                    if (user) {
                        if (!store.state.userState.currentUserConfig?.user) {
                            await store.dispatch('REQUEST_USER_CONFIG', user.key)
                        }

                        if (store.state.userState.currentUserConfig?.user.key.split('.')[0] !== to.params.id) {
                            store.commit('AdminTemplate/TOGGLE_GLOBAL_LOADER', true)
                            await store.dispatch('REQUEST_USER_CONFIG', user.key)
                            store.commit('AdminTemplate/TOGGLE_GLOBAL_LOADER', false)
                        }
                    } else {
                        next({
                            name: "Users"
                        })
                        errorNotification("User not found")
                    }

                }

                next()
            },
        },
        onSaveButtonClick: (ctx, wasClicked): void => {
            if (ctx.route.params.id) {
                store.dispatch('SAVE_CONFIG_WARN', store.state.userState.currentUserConfig?.user.key)
            }
        },
    },
    router: router,
    store: store,
}

Vue.use(AdminTemplate as unknown as PluginFunction<any>, adminTemplateOptions)

Vue.config.productionTip = false;

(window as any).mobilecheck = isMobile

export default new Vue({
    router,
    i18n,
    store,
    'render': (h): VNode => h(App),
}).$mount('#app')
