import { userState, UserState } from '@/store/users/state'
import { triggerState, TriggerState } from '@/store/triggers/state'
import statisticsState, { StatisticsState } from '@/store/statistics/state'
import { AdminTemplateModuleStore } from 'piramis-base-components/src/plugins/AdminTemplate/store/StoreTypes'

export type RootState = {
    statisticsState: StatisticsState
    userState: UserState;
    triggerState: TriggerState
    AdminTemplate: AdminTemplateModuleStore
}

const state: RootState = {
    userState,
    triggerState,
    statisticsState,
    AdminTemplate: {} as AdminTemplateModuleStore
}

export default state
