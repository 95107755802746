import { User } from '@/includes/types/User'
import { RootState } from '@/store/state'
import { UserConfig, UserConfigLimits } from '@/includes/services/UserService'

export default {
    SET_USERS_TO_STORE(state: RootState, users: Array<User>) {
        state.userState.allUsers = users.slice()
    },
    SET_USER_TO_STORE(state: RootState, { user, config, limits }: { user: User, config: UserConfig, limits: UserConfigLimits }) {
        state.userState.currentUserConfig = { user, config, limits }
    },
    CLEAR_USER_FROM_STORE(state: RootState) {
        state.userState.currentUserConfig = null
    }
}