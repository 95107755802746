import _mergeJSXProps from 'babel-helper-vue-jsx-merge-props';

export const ZapGlobalIcon = {
  name: 'ZapGlobalIcon',
  props: {
    size: {
      type: String,
      default: '24',
      validator: function validator(s) {
        return !isNaN(s) || s.length >= 2 && !isNaN(s.slice(0, s.length - 1)) && s.slice(-1) === 'x';
      }
    }
  },
  functional: true,
  render: function render(h, ctx) {
    var size = ctx.props.size.slice(-1) === 'x' ? ctx.props.size.slice(0, ctx.props.size.length - 1) + 'em' : parseInt(ctx.props.size) + 'px';
    var attrs = ctx.data.attrs || {};
    attrs.width = attrs.width || size;
    attrs.height = attrs.height || size;
    ctx.data.attrs = attrs;
    return h("svg", _mergeJSXProps([ {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "48.5",
        height: "44",
        viewBox: "0 0 48.5 44",
        fill: "none",
        stroke: "currentColor",
        "stroke-width": "0",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      },
      "class": "feather feather-activity"
    }, ctx.data ]), [ h("g", {
    }), h("path", {
      attrs: {
        d: "M18,44a2,2,0,0,1-.8-.16A2,2,0,0,1,16,41.75L17.73,28H2A2,2,0,0,1,.46,24.72l20-24A2,2,0,0,1,24,2.25L22.27,16H38a2,2,0,0,1,1.54,3.28l-20,24A2,2,0,0,1,18,44ZM6.27,24H20a2,2,0,0,1,1.5.68A2,2,0,0,1,22,26.25l-1.15,9.23L33.73,20H20a2,2,0,0,1-1.5-.68A2,2,0,0,1,18,17.75l1.15-9.23ZM39,43.5A9.5,9.5,0,1,1,48.5,34,9.51,9.51,0,0,1,39,43.5Zm-1.56-8a10.57,10.57,0,0,0,1.56,4,10.78,10.78,0,0,0,1.56-4Zm6.15,0a14.19,14.19,0,0,1-1.16,4,6.57,6.57,0,0,0,2.9-4Zm-10.92,0a6.57,6.57,0,0,0,2.9,4,13.65,13.65,0,0,1-1.16-4Zm10.92-3h1.74a6.59,6.59,0,0,0-2.9-4A13.65,13.65,0,0,1,43.59,32.5Zm-6.15,0h3.12A10.74,10.74,0,0,0,39,28.45,10.78,10.78,0,0,0,37.44,32.5Zm-4.77,0h1.74a14.19,14.19,0,0,1,1.16-4A6.57,6.57,0,0,0,32.67,32.5Z"
      }
    }) ]);
  }
};
