import statisticsMutations from './statistics/mutations'
import usersMutations from './users/mutations'
import { MutationTree } from 'vuex'
import triggersMutations from '@/store/triggers/mutations'

export default {
    EXEC_CALLBACK_IN_STORE(state, { type = 'unknown', callback }) {
        callback()
    },
    ...statisticsMutations,
    ...usersMutations,
    ...triggersMutations
}
